import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import {
  Table,
  TableBody,
  TableHeader,
  TableCell,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
import PropTypes from 'prop-types';
import { BorderRight } from '@material-ui/icons';
import imgLogo from '../../img/logoServiSaPequeno.png';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
  },
  section: {
    width: '100%',
    paddingVertical: 8,
    flexDirection: 'column',
    // alignContent: 'center',
    // justifyContent: 'flex-start',

    // textAlign: 'center',
  },
  sectionTitle: {
    width: '100%',
    flexDirection: 'column',
    paddingHorizontal: 20,
    // alignContent: 'center',
    // justifyContent: 'flex-start',

    // textAlign: 'center',
  },

  sectionReport: {
    width: '100%',
    paddingHorizontal: 20,
    flexDirection: 'column',
    // alignContent: 'center',
    // justifyContent: 'flex-start',

    // textAlign: 'center',
  },

  division: {
    width: '100%',

    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'flex-start',
    // textAlign: 'center',
    paddingBottom: 16,
  },
  divisionHeader: {
    width: '100%',

    flexDirection: 'column',
    borderBottom: 1,
    borderBottomColor: '#000',
    alignContent: 'center',
    justifyContent: 'flex-start',
    // textAlign: 'center',
    paddingVertical: 16,
  },
  divisionFooter: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 13,
    textAlign: 'center',
    marginTop: 60,
    marginLeft: 7,
    flexDirection: 'column',
    borderTop: 1,
    borderBottomColor: '#000',
    alignContent: 'center',
    justifyContent: 'flex-start',
    // textAlign: 'center',
  },
  title: {
    color: '#000',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 4,
    textAlign: 'center',
    marginTop: 16,
  },
  mainTitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    alignSelf: 'center',
    paddingLeft: 200,
    color: '#000',
  },
  subtitle: {
    color: '#000',
    fontSize: 10,
    fontWeight: 'bold',
    paddingTop: 12,
    marginBottom: 12,
  },
  common: {
    color: '#333',
    fontSize: 16,
    fontWeight: 'normal',
  },
  tablecontainer: {
    marginTop: 16,
    marginBottom: 16,
  },
  tableheader: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#000',
    border: 0,
    fontSize: 12,
  },

  tablecell: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#333',
    border: 0,
    fontSize: 10,
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
  },
  flexContainerEquipament: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
  },
  flexContainerVist: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
  },
  flexContainerCause: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '55%',
    flexDirection: 'row',
  },
  columContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '48%',
    paddingVertical: 2,

    flexDirection: 'column',
  },
  columContainerThree: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '31.3%',

    paddingVertical: 2,

    flexDirection: 'column',
  },
  columContainerFour: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '23%',

    paddingVertical: 2,

    flexDirection: 'column',
  },
  columContainerCause: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '120%',
    paddingHorizontal: 8,
    paddingVertical: 5,
    borderColor: '#000',
    BorderRight: 1,
    borderWidth: 1,
    flexDirection: 'column',
  },
  boxContainer: {
    borderColor: 'black',
    border: 1,
    width: '100%',
    height: 30,
    paddingTop: 5,
    textAlign: 'center',
  },
  boxContainerTitle: {
    borderColor: 'black',
    border: 1,
    width: '20%',
    padding: 5,
    height: 30,
    textAlign: 'center',
  },
  boxContainerEquipament: {
    borderColor: 'black',
    border: 1,
    width: '89%',
    padding: 5,
    height: 30,
    textAlign: 'center',
  },
  spacerLeft: {
    marginRight: 4,

    color: '#000',
    fontSize: 17,

    fontWeight: 'bold',
  },
  spacerLeftFooter: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    padding: 10,
    color: '#000',
    fontSize: 17,

    fontWeight: 'bold',
  },
  spacerRight: {
    width: 150,
    marginRight: 30,
  },
  spacerRightImg: {
    width: 30,
    marginRight: 91,
  },
  subjectBoxContainer: {
    height: 150,
    borderColor: 'black',
    border: 1,
    padding: 8,
    textAlign: 'justify',
    fontSize: 13,
    width: '100%',
  },
  subjectBoxContainerDescription: {
    height: 225,
    borderColor: 'black',
    border: 1,
    padding: 8,
    textAlign: 'justify',
    fontSize: 13,
    width: '100%',
  },
  subjectBoxContainerObservation: {
    height: 75,
    borderColor: 'black',
    border: 1,
    padding: 8,
    textAlign: 'justify',
    fontSize: 13,
    width: '100%',
  },
  fleetBoxContainer: {
    height: 80,
    padding: 8,
    textAlign: 'justify',
    fontSize: 13,
    width: '100%',
  },
  subjectContainer: {
    padding: 7,
  },
  numberContainer: {
    width: '50%',
  },
  sectionTitleOil: {
    width: '100%',
    flexDirection: 'column',
    paddingHorizontal: 5,
    fontSize: 20,
  },
  tableheaderOil: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    color: '#000',
    border: 0,
    fontSize: 7,
    fontWeight: 'bold',
  },
  divisionOil: {
    width: '100%',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'flex-start',

    textAlign: 'center',
    paddingBottom: 16,
    marginBottom: 16,
    // borderBottom: '1 solid #000',
  },
  tablecelloil: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#333',
  },
  tablecelloilCode: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#333',
    borderRight: '1 solid #000',
    fontSize: 7,
    fontWeight: 'bold',
  },
  imgOil: {
    width: 30,
  },
  tableheaderFreqServicesConclusion: {
    maxWidth: '100px',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    color: '#000',
    fontSize: 10,
    fontWeight: 'bold',
  },
  tablecellFreqServicesConclusion: {
    maxWidth: '100px',
    borderLeft: '1 solid #000',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 15,
    color: '#333',
    fontSize: 10,
  },
});

function MaintenanceDocument({
  maintenance,
  maintenanceToMaintenanceServices,
  maintenanceToMaintenancePlanFreqs,
}) {
  maintenance.maintenanceemployees = maintenance.maintenanceemployees.filter(
    item => item.employee !== null
  );
  const options = {
    timeZone: 'UTC',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };

  const planName = `${maintenanceToMaintenancePlanFreqs[0]?.maintenanceplanfreq?.maintenanceplan?.description} - ${maintenanceToMaintenancePlanFreqs[0]?.maintenanceplanfreq?.frequency}`;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.sectionTitle}>
          <View style={styles.divisionHeader}>
            <View style={styles.flexContainer}>
              <Image style={styles.spacerRightImg} source={imgLogo}></Image>
              <Text style={styles.subtitle}>
                <Text style={styles.spacerLeft}>
                  Ordem de Serviços
                  <Text></Text>
                </Text>
              </Text>

              <Text style={styles.boxContainerTitle}>
                Nº {maintenance.number}
              </Text>
            </View>
          </View>

          <Text style={styles.title}>{maintenance.type}</Text>
          <View style={styles.flexContainer}>
            <View style={styles.columContainer}>
              <Text style={styles.subtitle}>INICIO DA OCORRÊNCIA:</Text>
              <View style={styles.boxContainer}>
                <Text>
                  {maintenance.opening_date
                    ? new Date(maintenance.opening_date)
                        .toLocaleString('pt-BR', options)
                        .replace(',', '')
                    : '__/__/__'}
                </Text>
              </View>
            </View>
            <View style={styles.columContainer}>
              <Text style={styles.subtitle}>FIM DA OCORRÊNCIA:</Text>
              <View style={styles.boxContainer}>
                <Text>
                  {' '}
                  {maintenance.closing_date
                    ? new Date(maintenance.closing_date).toLocaleString(
                        'pt-BR',
                        options
                      )
                    : '__/__/__'}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.flexContainerEquipament}>
            <View style={styles.columContainerThree}>
              <Text style={styles.subtitle}>FROTA:</Text>
              <View style={styles.boxContainer}>
                <Text>{maintenance.equipment.code}</Text>
              </View>
            </View>

            <View style={styles.columContainerThree}>
              <Text style={styles.subtitle}>PLACA:</Text>
              <View style={styles.boxContainer}>
                <Text>{maintenance.equipment.plate}</Text>
              </View>
            </View>

            <View style={styles.columContainerThree}>
              <Text style={styles.subtitle}>MODELO:</Text>
              <View style={styles.boxContainer}>
                <Text>{maintenance.equipment.model}</Text>
              </View>
            </View>
          </View>

          <View style={styles.flexContainerEquipament}>
            <View style={styles.columContainerThree}>
              <Text style={styles.subtitle}>KM:</Text>
              <View style={styles.boxContainer}>
                <Text>
                  {' '}
                  {maintenance.km && maintenance.km.toLocaleString()}
                </Text>
              </View>
            </View>
            <View style={styles.columContainerThree}>
              <Text style={styles.subtitle}>HORIMETRO DO CAMINHÃO:</Text>
              <View style={styles.boxContainer}>
                <Text>
                  {(maintenance.timer01 || maintenance.timer01 > 0) &&
                    maintenance.timer01.toLocaleString()}
                </Text>
              </View>
            </View>
            <View style={styles.columContainerThree}>
              <Text style={styles.subtitle}>HORIMETRO EQUIPAMENTO:</Text>
              <View style={styles.boxContainer}>
                <Text>
                  {' '}
                  {(maintenance.timer02 || maintenance.timer02 > 0) &&
                    maintenance.timer02.toLocaleString()}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.division}>
            <Text style={styles.subtitle}>DESCRIÇÃO DE ABERTURA: </Text>
            <View style={styles.subjectBoxContainerDescription}>
              <Text style={styles.subjectContainerDescription}>
                {maintenance?.opening_description &&
                  maintenance?.opening_description}
              </Text>
            </View>
          </View>
          {maintenance?.observation && (
            <View style={styles.division}>
              <Text style={styles.subtitle}>OBSERVAÇÃO: </Text>
              <View style={styles.subjectBoxContainerObservation}>
                <Text style={styles.subjectContainerObservation}>
                  {maintenance?.observation && maintenance?.observation}
                </Text>
              </View>
            </View>
          )}

          <View style={styles.flexContainerVist}>
            <View style={styles.columContainerFour}>
              <Text style={styles.subtitle}>VISTO EXECUTANTE:</Text>
              <View style={styles.boxContainer}>
                <Text>{''}</Text>
              </View>
            </View>
            <View style={styles.columContainerFour}>
              <Text style={styles.subtitle}>DATA:</Text>
              <View style={styles.boxContainer}>
                <Text>{'__/__/__'}</Text>
              </View>
            </View>
            <View style={styles.columContainerFour}>
              <Text style={styles.subtitle}>VISTO RESPONSÁVEL:</Text>
              <View style={styles.boxContainer}>
                <Text>{''}</Text>
              </View>
            </View>
            <View style={styles.columContainerFour}>
              <Text style={styles.subtitle}>DATA:</Text>
              <View style={styles.boxContainer}>
                <Text>{'__/__/__'}</Text>
              </View>
            </View>
          </View>

          <View style={styles.divisionFooter}>
            <View style={styles.flexContainer}>
              <Text style={styles.spacerLeftFooter}>Servi-Sá Guindastes</Text>
            </View>
          </View>
        </View>
      </Page>

      {(maintenanceToMaintenanceServices &&
        maintenanceToMaintenanceServices[0]) ||
      (maintenance.maintenancepieces && maintenance.maintenancepieces[0]) ||
      (maintenance.maintenanceemployees &&
        maintenance.maintenanceemployees[0]) ? (
        <Page size="A4" style={styles.page}>
          <View style={styles.sectionReport}>
            <View style={styles.divisionHeader}>
              <View style={styles.flexContainer}>
                <Image style={styles.spacerRightImg} source={imgLogo}></Image>
                <Text style={styles.subtitle}>
                  <Text style={styles.spacerLeft}>
                    Ordem de Serviços
                    <Text></Text>
                  </Text>
                </Text>
                <Text style={styles.boxContainerTitle}>
                  Nº {maintenance.number}
                </Text>
              </View>
            </View>
            {maintenanceToMaintenanceServices &&
              maintenanceToMaintenanceServices[0] && (
                <View style={styles.section}>
                  <Text style={styles.title}>SERVIÇOS</Text>
                  <Table data={maintenanceToMaintenanceServices}>
                    <TableHeader textAlign="center">
                      <TableCell style={styles.tableheader}>SERVIÇO</TableCell>
                      <TableCell style={styles.tableheader}>
                        DESCRIÇÃO
                      </TableCell>
                    </TableHeader>
                    <TableBody textAlign="center">
                      <DataTableCell
                        style={styles.tablecell}
                        getContent={r => r?.maintenanceservice?.description}
                      />
                      <DataTableCell
                        style={styles.tablecell}
                        getContent={r => r?.description}
                      />
                    </TableBody>
                  </Table>
                </View>
              )}

            {maintenance.maintenancepieces && maintenance.maintenancepieces[0] && (
              <View style={styles.section}>
                <Text style={styles.title}>PEÇAS</Text>
                <Table data={maintenance.maintenancepieces}>
                  <TableHeader textAlign="center">
                    <TableCell style={styles.tableheader}>PEÇA</TableCell>
                    <TableCell style={styles.tableheader}>QUANTIDADE</TableCell>
                    <TableCell style={styles.tableheader}>VALOR</TableCell>
                    <TableCell style={styles.tableheader}>TOTAL</TableCell>
                  </TableHeader>
                  <TableBody textAlign="center">
                    <DataTableCell
                      style={styles.tablecell}
                      getContent={r => r.piece?.description}
                    />
                    <DataTableCell
                      style={styles.tablecell}
                      getContent={r => r.quantity}
                    />
                    <DataTableCell
                      style={styles.tablecell}
                      getContent={r => r.value}
                    />
                    <DataTableCell
                      style={styles.tablecell}
                      getContent={r => r.value * r.quantity}
                    />
                  </TableBody>
                </Table>
              </View>
            )}

            {maintenance.maintenanceemployees &&
              maintenance.maintenanceemployees[0] && (
                <View style={styles.section}>
                  <Text style={styles.title}>FUNCIONÁRIOS</Text>
                  <Table data={maintenance.maintenanceemployees}>
                    <TableHeader textAlign="center">
                      <TableCell style={styles.tableheader}>
                        FUNCIONARIO
                      </TableCell>
                      <TableCell style={styles.tableheader}>INICIO</TableCell>
                      <TableCell style={styles.tableheader}>FIM</TableCell>
                    </TableHeader>
                    <TableBody textAlign="center">
                      <DataTableCell
                        style={styles.tablecell}
                        getContent={r => r.employee.name}
                      />
                      <DataTableCell
                        style={styles.tablecell}
                        getContent={r =>
                          new Date(r.start)
                            .toLocaleString('pt-BR', options)
                            .replace(',', '')
                        }
                      />
                      <DataTableCell
                        style={styles.tablecell}
                        getContent={r =>
                          new Date(r.end)
                            .toLocaleString('pt-BR', options)
                            .replace(',', '')
                        }
                      />
                    </TableBody>
                  </Table>
                </View>
              )}

            <View style={styles.divisionFooter}>
              <View style={styles.flexContainer}>
                <Text style={styles.spacerLeftFooter}>Servi-Sá Guindastes</Text>
              </View>
            </View>
          </View>
        </Page>
      ) : null}
      {maintenanceToMaintenancePlanFreqs &&
        maintenanceToMaintenancePlanFreqs[0] && (
          <Page size="A4" style={styles.page}>
            <View style={styles.sectionReport}>
              <View style={styles.divisionHeader}>
                <View style={styles.flexContainer}>
                  <Image style={styles.spacerRightImg} source={imgLogo}></Image>
                  <Text style={styles.subtitle}>
                    <Text style={styles.spacerLeft}>
                      Ordem de Serviços
                      <Text></Text>
                    </Text>
                  </Text>
                  <Text style={styles.boxContainerTitle}>
                    Nº {maintenance.number}
                  </Text>
                </View>
              </View>
              <View style={styles.section}>
                <Text style={styles.title}>{planName}</Text>
                <Table
                  data={
                    maintenanceToMaintenancePlanFreqs[0].maintenanceplanfreq
                      ?.maintenanceplanfreqservices
                  }
                >
                  <TableHeader textAlign="center">
                    <TableCell style={styles.tableheader}>SERVIÇO</TableCell>
                    <TableCell style={styles.tableheaderFreqServicesConclusion}>
                      CONCLUSÃO
                    </TableCell>
                  </TableHeader>
                  <TableBody textAlign="center">
                    <DataTableCell
                      style={styles.tablecell}
                      getContent={r => r.maintenanceservice?.description}
                    />
                    <TableCell
                      style={styles.tablecellFreqServicesConclusion}
                    ></TableCell>
                  </TableBody>
                </Table>
              </View>
            </View>
            <View style={styles.divisionFooter}>
              <View style={styles.flexContainer}>
                <Text style={styles.spacerLeftFooter}>Servi-Sá Guindastes</Text>
              </View>
            </View>
          </Page>
        )}
    </Document>
  );
}

export function OilChangePDF({ oilChangeEquipments }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.sectionTitle}>
          <View style={styles.divisionHeader}>
            <View style={styles.flexContainer}>
              <Image style={styles.imgOil} source={imgLogo}></Image>
              <Text style={styles.subtitle}>
                <Text style={styles.spacerLeft}>TROCA DE ÓLEO</Text>
              </Text>
              <Text style={styles.subtitle}></Text>
            </View>
          </View>

          <Table data={oilChangeEquipments}>
            <TableHeader textAlign="center">
              <TableCell style={styles.tableheaderOil}>EQUIPAMENTO</TableCell>
              <TableCell style={styles.tableheaderOil}>KM ATUAL</TableCell>
              <TableCell style={styles.tableheaderOil}>KM ANTERIOR</TableCell>
              <TableCell style={styles.tableheaderOil}>TROCA DE ÓLEO</TableCell>
              <TableCell style={styles.tableheaderOil}>HORÍMETRO AT.</TableCell>
              <TableCell style={styles.tableheaderOil}>
                HORÍMETRO ANT.
              </TableCell>
              <TableCell style={styles.tableheaderOil}>DIFERENÇA H.</TableCell>
            </TableHeader>
            <TableBody textAlign="center">
              <DataTableCell
                style={styles.tablecelloilCode}
                getContent={r => r.code}
              />
              <DataTableCell
                style={styles.tablecell}
                getContent={r =>
                  r.odometers[0]?.km?.toLocaleString('pt-BR', {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 2,
                  }) ?? 0
                }
              />
              <DataTableCell
                style={styles.tablecell}
                getContent={r =>
                  r.preventiveMaintenances[0]?.km?.toLocaleString('pt-BR', {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 2,
                  }) ?? 0
                }
              />
              <DataTableCell
                style={styles.tablecell}
                getContent={r => {
                  const currentKm = r.odometers[0]?.km ?? 0;
                  const oldKm = r.preventiveMaintenances[0]?.km ?? 0;
                  return typeof currentKm === 'number' && //
                    typeof oldKm === 'number' &&
                    !isNaN(currentKm) &&
                    !isNaN(oldKm)
                    ? (currentKm - oldKm).toLocaleString({
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 2,
                      })
                    : 0;
                }}
              />
              <DataTableCell
                style={styles.tablecell}
                getContent={r =>
                  r.odometers[0]?.timer01?.toLocaleString('pt-BR', {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 2,
                  }) ?? 0
                }
              />
              <DataTableCell
                style={styles.tablecell}
                getContent={r =>
                  r.preventiveMaintenances[0]?.timer01?.toLocaleString(
                    'pt-BR',
                    {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 2,
                    }
                  ) ?? 0
                }
              />
              <DataTableCell
                style={styles.tablecell}
                getContent={r => {
                  const currentTimer = r.odometers[0]?.timer01 ?? 0;
                  const oldTimer = r.preventiveMaintenances[0]?.timer01 ?? 0;

                  return typeof currentTimer === 'number' && //
                    typeof oldTimer === 'number' &&
                    !isNaN(currentTimer) &&
                    !isNaN(oldTimer)
                    ? (currentTimer - oldTimer).toLocaleString({
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 2,
                      })
                    : 0;
                }}
              />
            </TableBody>
          </Table>
        </View>
      </Page>
    </Document>
  );
}

OilChangePDF.propTypes = {
  oilChangeEquipments: PropTypes.arrayOf(
    PropTypes.shape({
      maintenances: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          uuid: PropTypes.string,
          number: PropTypes.number,
          opening_date: PropTypes.string,
          opening_description: PropTypes.string,
          closing_date: PropTypes.string,
          closing_description: PropTypes.string,
          observation: PropTypes.string,
          status: PropTypes.string,
          equipment: PropTypes.shape({
            code: PropTypes.string,
          }),
          maintenancepieces: PropTypes.shape({
            uuid: PropTypes.string,
          }),
          maintenanceemployees: PropTypes.shape({
            uuid: PropTypes.string,
          }),
        })
      ),
    })
  ),
};

OilChangePDF.defaultProps = {
  oilChangeEquipments: [],
};

MaintenanceDocument.propTypes = {
  maintenance: PropTypes.shape({
    type: PropTypes.string,
    uuid: PropTypes.string,
    number: PropTypes.number,
    opening_date: PropTypes.string,
    opening_description: PropTypes.string,
    closing_date: PropTypes.string,
    closing_description: PropTypes.string,
    observation: PropTypes.string,
    status: PropTypes.string,
    timer01: PropTypes.number,
    timer02: PropTypes.number,
    km: PropTypes.number,
    equipment: PropTypes.shape({
      code: PropTypes.string,
    }),
    maintenancepieces: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
      })
    ),
    maintenanceemployees: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
      })
    ),
  }),
};

MaintenanceDocument.defaultProps = {
  maintenance: null,
};

export default MaintenanceDocument;
