import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { FormStyled, Row, Column } from '../../css/styles/form';
import { SelectStyled } from '../../css/styles/select';

const handleValidate = ({
  opening_description,
  opening_date,
  type,
  time,
  equipment,
  km,
  timer01,
}) => {
  const errors = {};

  if (!opening_description) errors.opening_description = 'OBRIGATÓRIO';

  if (!opening_date) errors.opening_date = 'OBRIGATÓRIO';

  if (!time) errors.time = 'OBRIGATÓRIO';

  if (!type) errors.type = 'OBRIGATÓRIO';

  if (!equipment) errors.equipment = 'OBRIGATÓRIO';

  if (!km) errors.km = 'OBRIGATÓRIO';

  if (!timer01) errors.timer01 = 'OBRIGATÓRIO';

  return errors;
};

const handleSubmit = props => async (values, { setSubmitting }) => {
  if (!props.maintenance) {
    await props.onCreation({ ...values });
  } else {
    await props.onUpdate({ ...values });
  }

  setSubmitting(false);
};

const MaintenanceForm = props => {
  const { equipments, equipment, maintenance, base, bases, onNew } = props;

  const typeOptions = [
    {
      value: 'PREVENTIVA',
      label: 'PREVENTIVA',
    },
    {
      value: 'CORRETIVA',
      label: 'CORRETIVA',
    },
    {
      value: 'PREVENTIVA/CORRETIVA',
      label: 'PREVENTIVA/CORRETIVA',
    },
  ];

  const situationOptions = [
    {
      value: 'INICIADA',
      label: 'INICIADA',
    },
    {
      value: 'EM DIAGNOSTICO',
      label: 'EM DIAGNOSTICO',
    },
    {
      value: 'EFETUANDO REPAROS',
      label: 'EFETUANDO REPAROS',
    },
    {
      value: 'AGUARDANDO PEÇA',
      label: 'AGUARDANDO PEÇA',
    },
  ];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        base: maintenance ? maintenance?.base : null,

        opening_description: !maintenance
          ? ''
          : maintenance.opening_description,

        opening_date: !maintenance
          ? moment().format('YYYY-MM-DD')
          : maintenance.opening_date.substring(0, 10),

        time: !maintenance ? '' : maintenance.opening_date.substring(11, 16),

        type: !maintenance ? '' : maintenance.type,

        observation: !maintenance ? '' : maintenance.observation,

        equipment:
          maintenance && maintenance.equipment
            ? maintenance.equipment
            : equipment || '',

        situation: maintenance ? maintenance.situation : 'INICIADA',

        km: maintenance ? maintenance.km : '',

        timer01: maintenance ? maintenance.timer01 : '',

        timer02: maintenance && maintenance.timer02 ? maintenance.timer02 : '',
        services: !maintenance ? '' : maintenance.services,
      }}
      validate={handleValidate}
      onSubmit={handleSubmit(props)}
    >
      {({
        values,

        handleReset,

        handleBlur,

        handleChange,

        errors,

        touched,

        isSubmitting,

        dirty,

        setFieldValue,
      }) => (
        <FormStyled>
          <h1>{equipment ? 'ALTERAR MANUTENÇÃO' : 'NOVA MANUTENÇÃO'}</h1>
          <ul>
            <Row>
              <Column>
              <strong>BASE</strong>
                <Select
                  styles={SelectStyled}
                  id="base"
                  name="base"
                  value={values.base}
                  placeholder="Selecione uma base"
                  onChange={selectedOption => {
                    setFieldValue('base', selectedOption);
                  }}
                  isSearchable
                  options={bases}
                  isLoading={false}
                  defaultValue={{ value: base?.uuid, label: base?.description }}
                  getOptionLabel={item => item.description}
                  getOptionValue={item => item.uuid}
                  loadingMessage={() => 'Buscando bases'}
                  noOptionsMessage={() => 'Não há bases para exibir'}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <strong>DATA DE ABERTURA</strong>
                <input
                  id="opening_date"
                  name="opening_date"
                  type="date"
                  value={values.opening_date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.opening_date && touched.opening_date && (
                  <span>{errors.opening_date}</span>
                )}
              </Column>
              <Column>
                <strong>HORA DE ABERTURA</strong>
                <input
                  id="time"
                  name="time"
                  type="time"
                  value={values.time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.time && touched.time && <span>{errors.time}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>KM</strong>
                <input
                  id="km"
                  name="km"
                  type="number"
                  value={values.km}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.km && touched.km && <span>{errors.km}</span>}
              </Column>
              <Column>
                <strong>HORIMETRO CAMINHÃO</strong>
                <input
                  id="timer01"
                  name="timer01"
                  type="number"
                  value={values.timer01}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.timer01 && touched.timer01 && (
                  <span>{errors.timer01}</span>
                )}
              </Column>
              <Column>
                <strong>HORIMETRO EQUIPAMENTO</strong>
                <input
                  id="timer02"
                  name="timer02"
                  type="number"
                  value={values.timer02}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.timer02 && touched.timer02 && (
                  <span>{errors.timer02}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>VEÍCULO</strong>
                <Select
                  styles={SelectStyled}
                  id="equipment"
                  value={values.equipment}
                  placeholder="Selecione um veículo"
                  onChange={selectedOption => {
                    setFieldValue('equipment', selectedOption);
                  }}
                  isSearchable
                  options={equipments}
                  getOptionLabel={item => item.code}
                  getOptionValue={item => item.uuid}
                  name="equipment"
                  isLoading={false}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  loadingMessage={() => 'Buscando veículos'}
                  noOptionsMessage={() => 'Não há veículos cadastrados'}
                />

                {errors.equipment && touched.equipment && (
                  <span>{errors.equipment}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>TIPO</strong>
                <select
                  id="type"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" />
                  {typeOptions.map(type => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>

                {errors.type && touched.type && <span>{errors.type}</span>}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>SITUAÇÃO</strong>
                <select
                  id="situation"
                  name="situation"
                  value={values.situation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" />
                  {situationOptions.map(situation => (
                    <option key={situation.value} value={situation.value}>
                      {situation.label}
                    </option>
                  ))}
                </select>

                {errors.situation && touched.situation && (
                  <span>{errors.situation}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>DESCRIÇAO DE ABERTURA</strong>
                <textarea
                  id="opening_description"
                  name="opening_description"
                  type="text"
                  value={values.opening_description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.opening_description && touched.opening_description && (
                  <span>{errors.opening_description}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>SERVIÇO REALIZADOS</strong>
                <input
                  id="services"
                  name="services"
                  type="text"
                  value={values.services}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.services && touched.services && (
                  <span>{errors.services}</span>
                )}
              </Column>
            </Row>

            <Row>
              <Column>
                <strong>OBSERVAÇÃO</strong>
                <input
                  id="observation"
                  name="observation"
                  type="text"
                  value={values.observation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {errors.observation && touched.observation && (
                  <span>{errors.observation}</span>
                )}
              </Column>
            </Row>

            <Row>
              <div className="buttons-container">
                <button type="submit" disabled={isSubmitting}>
                  SALVAR
                </button>

                <button
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty || isSubmitting}
                >
                  RESET
                </button>

                {maintenance && (
                  <button type="button" onClick={onNew}>
                    LIMPAR
                  </button>
                )}
              </div>
            </Row>
          </ul>
        </FormStyled>
      )}
    </Formik>
  );
};

MaintenanceForm.propTypes = {
  equipments: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      code: PropTypes.string,
    })
  ),
  equipment: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  serviceequipment: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  maintenance: PropTypes.shape({
    number: PropTypes.number,
    opening_description: PropTypes.string,
    opening_date: PropTypes.string,
    type: PropTypes.string,
    observation: PropTypes.string,
    employee: PropTypes.shape({
      uuid: PropTypes.string,
    }),
    situation: PropTypes.string,
    km: PropTypes.number,
    timer01: PropTypes.number,
    timer02: PropTypes.number,
    services: PropTypes.string,
    equipment: PropTypes.shape({
      uuid: PropTypes.string,
    }),
  }),
  onNew: PropTypes.func.isRequired,
};

MaintenanceForm.defaultProps = {
  equipments: [],
  equipment: null,
  serviceequipment: null,
  maintenance: null,
};

export default MaintenanceForm;
